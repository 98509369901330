import React from "react";
import BaseVMComponent from "../BaseVMComponent";
import OrdersManagementPageViewModel from "../../ViewModels/Orders/OrdersManagementPageViewModel";
import AllOrdersTableComponent from "../Orders/AllOrdersTableComponent";
import {NavLink, useNavigate} from "react-router-dom";
import ResponsiveButton from "../Shared/ResponsiveButton";


interface State {
}
class OrdersManagementPageComponent extends BaseVMComponent<any, State, any, OrdersManagementPageViewModel> {

  private readonly tableRef: any
  state: State = {
    showDone: false,
  }


  constructor(props: any) {
    super(props);

    this.tableRef = React.createRef();
  }

  protected initViewModel(): void {
    this.viewModel = new OrdersManagementPageViewModel()
  }

  private onSuccessfullySaved() {
    (this.tableRef.current as any).reloadOrders()
  }

  render(): JSX.Element {
    return (
      <div className="row m-2">
        <div className="col-md-12">
          <div className="App">
            <header className="App-header">
              <div className={"d-flex justify-content-between flex-wrap"}>
                <div className={""}>
                  <h2>Auftragsverwaltung</h2>
                </div>

                <NavLink
                  to={"/admin/orders/create-order"}
                >
                  <ResponsiveButton
                    title={"Neuen Auftrag erstellen"}
                    responsiveTitle={"+"}
                  />
                </NavLink>
              </div>

              <div className="mt-4">
                <AllOrdersTableComponent
                  innerRef={this.tableRef}
                />
              </div>
            </header>
          </div>
        </div>
        {/* /.col */}
      </div>
    )
  }
}

export default OrdersManagementPageComponent;