import BaseVMComponent from "../BaseVMComponent";
import CreateClientButtonViewModel from "../../ViewModels/CreateClientButtonViewModel";
import React from "react";
import CreateClientModal from "../Clients/CreateClientModal";
import ResponsiveButton from "./ResponsiveButton";

interface Props {
  onSuccessfullySaved: () => void,
  buttonLabel: string,
  className: string,
}
interface State {
  createModalOpen: boolean,
}
export default class CreateClientButton extends BaseVMComponent<Props, State, any, CreateClientButtonViewModel> {
  static defaultProps = {
    onSuccessfullySaved: () => {},
    buttonLabel: "Neuen Mandanten erstellen",
    className: "",
  }

  constructor(props: any) {
    super(props);

    this.state = {
      createModalOpen: false,
    }
  }

  protected initViewModel(): void {
    this.viewModel = new CreateClientButtonViewModel()
  }

  private onOpenModalButtonClick() {
    this.setState({
      createModalOpen: true,
    })
  }

  render() {
    return (
      <>
        <ResponsiveButton
          title={this.props.buttonLabel}
          responsiveTitle={"+"}
          onClick={this.onOpenModalButtonClick.bind(this)}
        />

        { this.state.createModalOpen &&
          <CreateClientModal
            onModalClose={() => {
              this.setState({
                createModalOpen: false,
              })
            }}
            onSuccessfullySaved={() => {
              this.props.onSuccessfullySaved()
            }}
          />
        }
      </>
    );
  }
}