import TableComponent, {ActionButtonsCol, TableColumn} from "../Shared/TableComponent";
import {DateService} from "../../Services/DateService";
import React, {Component} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {FormCheck, FormGroup} from "react-bootstrap";
import {Order} from "../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";
import {AscOrDesc, IItemOrder} from "../../Services/APIServiceInterfaces/IItemOrder";


export type PossibleOrderItemOrderColNames = "id" | "date" | "client" | "address" | "orderStatus"
export interface OrderItemOrder extends IItemOrder {
  colName: PossibleOrderItemOrderColNames,
}
interface Props {
  title?: string,
  page: number,
  showSimpleTable: boolean,
  loading: boolean,
  rowLimit: number|null,
  orders: Order[],
  onPageChange: (page: number) => void,
  offset?: number,
  limit?: number,
  totalCount: number,
  onShowDoneChange: (showDone: boolean) => void,
  orderedBy: OrderItemOrder,
  onOrderedByChanged: (itemOrder: OrderItemOrder) => void,
}
interface _Props extends Props {
  navigation: NavigateFunction,
}
interface State {
  showDone: boolean,
}
class _OrdersTableComponent extends Component<_Props, State> {

  constructor(props: any) {
    super(props);

    this.state = {
      showDone: false,
    }
  }

  private onPageChange(page: number) {
    this.props.onPageChange(page)
  }

  private onRowDetailsClick(rowIndex: number) {
    const clickedObject = this.props.orders[rowIndex]
    this.props.navigation("/admin/orders/order/" + clickedObject.id)
  }

  private onColumnHeaderClick(
    headerKey: PossibleOrderItemOrderColNames,
  ) {
    let ascOrDesc: AscOrDesc = "asc"
    if (this.props.orderedBy.colName === headerKey && this.props.orderedBy.ascOrDesc === ascOrDesc) {
      // change to desc
      ascOrDesc = "desc"
    }

    this.props.onOrderedByChanged({
      colName: headerKey,
      ascOrDesc: ascOrDesc,
    })
  }

  private getTableColumns(): TableColumn[] {
    const idCol: TableColumn = {
      title: "ID",
      valueKey: "id",
      onColumnHeaderClick: () => this.onColumnHeaderClick("id")
    }

    const dateCol: TableColumn = {
      title: "gewünschtes Zustelldatum",
      valueKey: "date",
      resolveValue: (tableDataEntry: Order) => {
        if ((tableDataEntry as Order).date == null) {
          return "–"
        }
        return DateService.getShortDateString(new Date((tableDataEntry as Order).date!))
      },
      onColumnHeaderClick: () => this.onColumnHeaderClick("date")
    }

    const clientCol: TableColumn = {
      title: "Kunde",
      valueKey: "client",
      resolveValue: (tableDataEntry: Order) => {
        return (tableDataEntry as Order).client.fullName
      },
      onColumnHeaderClick: () => this.onColumnHeaderClick("client")
    }

    const addressCol: TableColumn = {
      title: "Adresse",
      valueKey: "address",
      resolveValue: (order: Order) => {
        if (order.address != null) {
          return order.address.addressAsString || "Adresse konnte nicht geladen werden"
        }
        if (order.client != null && order.client.address != null) {
          return order.client.address.addressAsString || "Adresse konnte nicht geladen werden"
        }
        return "-"
      },
      onColumnHeaderClick: () => this.onColumnHeaderClick("address")
    }

    const statusCol: TableColumn = {
      title: "Status",
      valueKey: "orderStatus",
      resolveValue: (tableDataEntry: Order) => {
        const order = (tableDataEntry as Order)
        return order.orderStatus.name
      },
      backgroundColor: (tableDataEntry: Order) => {
        const order = (tableDataEntry as Order)
        const colors: Array<string> = [
          "#9EA7AD",
          "#2FCCFF",
          "hsl(102,71%,79%)",
          "#FCE83B",
          "#FFB300",
          "#FF3938"
        ]
        return colors[order.orderStatus.queuePosition]
      },
      onColumnHeaderClick: () => this.onColumnHeaderClick("orderStatus")
    }

    if (this.props.showSimpleTable) {
      return [
        dateCol,
        clientCol,
        statusCol,
      ]
    }

    return [
      idCol,
      dateCol,
      clientCol,
      addressCol,
      statusCol,
      ActionButtonsCol,
    ]
  }

  render() {
    return (
      <>
        { !this.props.showSimpleTable &&
          <div className={"mb-3 d-flex justify-content-end"}>
            <FormGroup controlId={"show-done-orders"}>
              <FormCheck
                id={"show-done-orders"}
                title={"Abgeschlossene anzeigen"}
                label={"Abgeschlossene anzeigen"}
                style={{
                  color: "gray",
                }}
                checked={this.state.showDone}
                onChange={(value) => {
                  this.setState({
                    showDone: value.target.checked,
                  }, () => {
                    this.props.onShowDoneChange(this.state.showDone)
                  })
                }}
              />
            </FormGroup>
          </div>
        }

        <TableComponent
          title={this.props.title}
          columns={this.getTableColumns()}
          tableData={this.props.orders}
          isLoading={this.props.loading}
          offset={this.props.offset}
          limit={this.props.limit}
          totalCount={this.props.totalCount}
          pageNumber={this.props.page}
          onPageClick={this.onPageChange.bind(this)}
          onRowDetailsClick={(rowIndex, tableDataEntry) => {
            this.onRowDetailsClick(rowIndex)
          }}
          showSimpleTable={this.props.showSimpleTable}
          itemOrder={this.props.orderedBy}
        />
      </>
    )
  }
}


export default function OrdersTableComponent(props: Props) {
  const navigation = useNavigate();

  return (
    <_OrdersTableComponent
      {...props}
      navigation={navigation}
    />
  )
}
