import React from "react";
import ClientsTableComponent from "../../Clients/ClientsTableComponent";
import BaseVMComponent from "../../BaseVMComponent";
import ClientsManagementPageViewModel from "../../../ViewModels/Clients/ClientsManagementPageViewModel";
import CreateClientButton from "../../Shared/CreateClientButton";
import {Button} from "react-bootstrap";
import PageWrapper from "../../Shared/PageWrapper";
import ClientFilterFormModal, {ClientFilterData} from "./ClientsManagement/ClientFilterFormModal";


interface State {
  searchModalOpened: boolean,
  filterData: ClientFilterData,
}
class ClientsManagementPageComponent extends BaseVMComponent<any, State, any, ClientsManagementPageViewModel> {

  private readonly tableRef: any

  constructor(props: any) {
    super(props);

    this.tableRef = React.createRef();
    this.state = {
      searchModalOpened: false,
      filterData: this.getInitialFilterData(),
    }
  }

  protected initViewModel(): void {
    this.viewModel = new ClientsManagementPageViewModel()
  }

  private getInitialFilterData(): ClientFilterData {
    return {
      name: null,
      location: null,
      postalCode: null,
      street: null,
      lastOrderFrom: null,
      lastOrderTo: null,
      lastContainerConfirmationFrom: null,
      lastContainerConfirmationTo: null,
      containersAtClientFrom: null,
      containersAtClientTo: null,
    }
  }

  private onSuccessfullySaved() {
    (this.tableRef.current as any).reloadClients()
  }

  render(): JSX.Element {
    return (
      <PageWrapper
        header={
          <div className={"row"}>
            <div className={"col-md-6"}>
              <div className={"d-block d-md-none mb-2"}>
                <div className={"d-flex justify-content-between flex-wrap"}>
                  <div className={""}>
                    <h2>Mandantenverwaltung</h2>
                  </div>
                  <div className={""}>
                    <CreateClientButton
                      onSuccessfullySaved={this.onSuccessfullySaved.bind(this)}
                      buttonLabel={"+"}
                    />
                  </div>
                </div>
              </div>

              <div className={"d-none d-md-block"}>
                <h2>Mandantenverwaltung</h2>
              </div>
            </div>
            <div className={"col-md-6 d-md-flex justify-content-end align-items-center"}>
              <Button
                className={"mr-2"}
                variant={"secondary"}
                onClick={() => {
                  this.setState({
                    searchModalOpened: true,
                  })
                }}
              >
                Suche
              </Button>

              <div className={"d-none d-md-block"}>
                <CreateClientButton
                  onSuccessfullySaved={this.onSuccessfullySaved.bind(this)}
                />
              </div>
            </div>
          </div>
        }
      >
        <div className="mt-4">
          <ClientsTableComponent
            innerRef={this.tableRef}
            clientFilter={this.state.filterData}
          />

          { this.state.searchModalOpened &&
            <ClientFilterFormModal
              filterData={this.state.filterData}
              onSubmit={(filterData) => {
                this.setState({
                  filterData: filterData,
                  searchModalOpened: false,
                })
              }}
              onReset={() => {
                this.setState({
                  filterData: this.getInitialFilterData(),
                  searchModalOpened: false,
                })
              }}
              onClose={() => {
                this.setState({
                  searchModalOpened: false,
                })
              }}
            />
          }
        </div>
      </PageWrapper>
    )
  }
}

export default ClientsManagementPageComponent;