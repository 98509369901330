import React, {Component, Fragment} from "react";
import ClientOrdersTableComponent from "../../Orders/ClientOrdersTable/ClientOrdersTableComponent";
import {NavLink, useParams} from "react-router-dom";
import PageWrapper from "../../Shared/PageWrapper";
import BreadCrumbs from "../../Shared/Breadcrumbs/BreadCrumbs";


interface Props {
  clientId: number,
  page: number,
}
class _ClientOrdersPage extends Component<Props, {}> {

  render() {
    return (
      <div>
        <BreadCrumbs
          breadCrumbs={[
            {name: "Mandantendetails", url: `/admin/clients/client/${this.props.clientId}`},
            {name: "Aufträge von Mandant"},
          ]}
        />

        <div className={"row"}>
          <div className={"col-12"}>
            <h3>Aufträge von Mandant</h3>
          </div>
        </div>

        <ClientOrdersTableComponent
          clientId={this.props.clientId}
          page={this.props.page}
          showSimpleTable={false}
        />
      </div>
    )
  }
}


export default function ClientOrdersPage() {
  const { clientId, page } = useParams();

  return (
    <_ClientOrdersPage
      clientId={parseInt(clientId!)}
      page={parseInt(page!)}
    />
  )
}
