import React, {CSSProperties, useContext, useState} from "react";


interface Props {
  title: string,
  responsiveTitle: string,
  onClick?: () => void,
}

export default function ResponsiveButton(props: Props) {
  const [variable, set_variable] = useState(false)

  return (
    <button
      className={"btn btn-primary"}
      onClick={() => {
        if (props.onClick) {
          props.onClick()
        }
      }}
    >
      <span className={"d-none d-md-inline"}>{props.title}</span>
      <span className={"d-inline d-md-none"}>{props.responsiveTitle}</span>
    </button>
  )

}

const style: { [key: string]: CSSProperties } = {}
