import React, {Fragment} from "react";
import BaseVMComponent from "../../BaseVMComponent";
import {NavLink, Outlet, useNavigate, useParams} from "react-router-dom";
import ClientForm from "../../Clients/ClientForm";
import ClientDetailsPageViewModel from "../../../ViewModels/Pages/Client/ClientDetailsPageViewModel";
import LoadingComponent from "../../Shared/LoadingComponent";
import {Button} from "react-bootstrap";
import ContainersAtClientComponent from "../../Shared/ContainersAtClientComponent";
import ContainersAtClientTable from "../../Clients/ContainersAtClientTable";
import ClientOrdersTableComponent from "../../Orders/ClientOrdersTable/ClientOrdersTableComponent";
import ClientDetailsPageWrapperViewModel from "../../../ViewModels/Pages/Client/ClientDetailsPageWrapperViewModel";
import PageWrapper from "../../Shared/PageWrapper";
import BreadCrumbs from "../../Shared/Breadcrumbs/BreadCrumbs";


interface Props {
  clientId: number,
}
interface State {
}
class _ClientDetailsPageWrapper extends BaseVMComponent<Props, State, any, ClientDetailsPageWrapperViewModel> {

  constructor(props: Props) {
    super(props);

    this.state = {
      inEditMode: false,
    }
  }

  protected initViewModel(): void {
    this.viewModel = new ClientDetailsPageWrapperViewModel(
      this.props.clientId
    )
  }

  async componentDidMount() {
    this.viewModel.loadClient()
  }

  render(): JSX.Element {
    return (
      <PageWrapper
        header={
          <BreadCrumbs
            breadCrumbs={[
              {name: "Mandanten", url: "/admin/clients"},
              {name: this.viewModel.client?.fullName!},
            ]}
          />
        }
      >
        <Fragment>
          <h2>{this.viewModel.client?.fullName}</h2>
          <Outlet />
        </Fragment>
      </PageWrapper>
    )
  }
}


const ClientDetailsPageWrapper: any = (props: Props) => {
  const navigation = useNavigate();
  const { clientId } = useParams();

  return (
    <_ClientDetailsPageWrapper
      {...props}
      clientId={parseInt(clientId!)}
      // navigation={navigation}
      // page={parseInt(page || "1")}
    />
  )
}


export default ClientDetailsPageWrapper;