import React, {CSSProperties, useState} from "react";
import {NavLink} from "react-router-dom";


export interface IBreadCrumb {
  name: string,
  url?: string,
}
interface Props {
  breadCrumbs: IBreadCrumb[],
}

export default function BreadCrumbs(props: Props) {
  const [variable, set_variable] = useState(false)

  let breadCrumbElements = props.breadCrumbs.map((breadCrumb, index) => {
    return (
      <li key={index} className="breadcrumb-item">
        { breadCrumb.url &&
          <NavLink to={breadCrumb.url}>
            {breadCrumb.name}
          </NavLink>
        }
        { !breadCrumb.url &&
          breadCrumb.name
        }
      </li>
    )
  })

  return (
    <div className={"row mb-3"}>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          { breadCrumbElements }
        </ol>
      </nav>
    </div>
  )

}

const style: { [key: string]: CSSProperties } = {}
