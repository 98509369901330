import React from "react";
import BaseVMComponent from "../BaseVMComponent";
import ContainersTableComponent from "./ContainersTableComponent";
import CreateProductButton from "../Shared/CreateProductButton";
import ContainersManagementPageViewModel from "../../ViewModels/Containers/ContainersManagementPageViewModel";
import CreateContainerButton from "../Shared/CreateContainerButton";


interface State {
}
class ContainersManagementPageComponent extends BaseVMComponent<any, State, any, ContainersManagementPageViewModel> {

  private readonly tableRef: any

  constructor(props: any) {
    super(props);

    this.tableRef = React.createRef();
  }

  protected initViewModel(): void {
    this.viewModel = new ContainersManagementPageViewModel()
  }

  private onSuccessfullySaved() {
    (this.tableRef.current as any).reloadOrders()
  }

  render(): JSX.Element {
    return (
      <div className="row m-2">
        <div className="col-sm-12">
          <div className="App">
            <header className="App-header">
              <div className={"row"}>
                <div className={"col-md-8"}>
                  <h2>Gebindeverwaltung</h2>
                </div>
                <div className={"col-md-4 d-flex justify-content-end"}>
                  <CreateContainerButton
                    onSuccessfullySaved={this.onSuccessfullySaved.bind(this)}
                  />
                </div>
              </div>

              <div className="mt-4">
                <ContainersTableComponent
                  innerRef={this.tableRef}
                />
              </div>
            </header>
          </div>
        </div>
        {/* /.col */}
      </div>
    )
  }
}

export default ContainersManagementPageComponent;